<template>
<div class="home">
    <br />
    <br />
    <div class="container">
        <div class="jumbotron" style="padding-top: 5vh;">
            <h1 class="display-4">Welcome to the FMS Store</h1>
            <p class="lead">Here are the Latest Products from Faceless Mirage Studio.</p>
            <hr class="my-4" />
            <!-- EDIT JUMBOTRON HERE-->
            <h4><u>Promo's & Discounts</u></h4>
                <p>
                    Free shipping for purchases over $100.
                    <br />
                    Please check frequently for holiday specials!
                </p>
            
            <hr />

            <h4>In Stock: Order via Custom & Premade Forms</h4>
                <ul>
                    <li>11oz. Mugs, Heat Changing</li>
                </ul>

            <h4>Pre-Orders: Order via Custom Forms*</h4>
                <ul>
                    <li>11oz. Mugs, Colored</li>
                    <li>Polyester Shirts, S/M/L/XL</li>
                </ul>

            <h4>Coming Soon: Order via Email Request*</h4>
                <ul>
                    <li>Coasters, Circle/Square/Rounded</li>
                    <li>Face Masks, Double/Triple Layer</li>
                    <li>Mouse Pads</li>
                    <li>Photo Tile</li>
                    <li>Plates</li>
                    <li>Puzzles, Heart Shapped (75 pcs)</li>
                </ul>

            <h4>Special Requests: Available*</h4>
                <ul>
                    <li>Please email for any special requests.</li>
                </ul>
                <p>
                    *Please note certain products may require an initial bulk buy.
                </p>
            <!-- *************************************-->
            <router-link to="/shop"><a class="btn btn-warning btn-lg" href="#" role="button">Shop</a></router-link>
        </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
</div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Home',
    components: {

    }
}
</script>
        
<style scoped>
#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4rem;
    /* Footer height */
}
</style>
